<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div>
			<BhLoading :show="loading" />
			<div class="dF aC" style="font-size: 24px; gap: 20px" v-if="selectedDomain">
				<a-icon type="arrow-left" @click="$router.go(-1)" />
				<strong>{{ selectedDomain.name }} <span v-if="project">({{ project.name }})</span></strong>
				<span class="project-button" v-if="selectedDomain.state === 'unverified'" style="background-color: #ffc107">
					Unverified
				</span>
				<span class="project-button" v-else-if="selectedDomain.state === 'active'"
					style="background-color: #28c791">
					Active
				</span>
			</div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF aC" style="gap: 25px">
					<div class="dF aC" style="gap: 5px">
						<span>Print:</span>
						<a @click.prevent="downloadPDF" href="javascript:;" class="text-primary">
							Charts
						</a>
						<PDFOrientation class="ml-2" />
					</div>
					<!-- <div class="dF aC" style="gap: 5px">
					<span>Download:</span>
					<a @click.prevent="" href="javascript:;" class="text-primary">CSV</a>
				</div> -->

					<DatePicker @current-times-update="fetchChartData($event)" :disabled-compare-date="true" />

					<div class="dF aC" style="gap: 10px">
						<a-button v-if="!project.susspended" @click="lockModal = true"><a-icon type="lock" />LOCK
							PROJECT</a-button>
						<a-button v-else @click="unlockProject"><a-icon type="unlock" />UNLOCK PROJECT</a-button>
					</div>
				</div>
				<a-tab-pane key="1" tab="Overview">
					<vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="false"
						:pdf-content-width="currentWidth" :manual-pagination="true" :html-to-pdf-options="{
							margin: [5, 5, 5, 5],
							image: { type: 'jpeg', quality: 2 },
							enableLinks: true,
							html2canvas: { scale: 1, useCORS: true },
							jsPDF: {
								orientation: pdfOrientation
							}
						}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
						<section slot="pdf-content">
							<a-row :gutter="24" class="html2pdf__page-break">
								<a-col :span="8">
									<a-card>
										<a-statistic :value="100" suffix="%" :value-style="{ color: '#2B93C6' }">
											<template #title>
												<h3 style="color: #2B93C6;">Processed</h3>
											</template>
											<template #prefix>
												<h3 class="mr-3" style="color: #2B93C6">{{ totalAccepted }}</h3>
											</template>
										</a-statistic>
									</a-card>
								</a-col>
								<a-col :span="8">
									<a-card>
										<a-statistic :value="deliveredPercentage" suffix="%"
											:value-style="{ color: '#1EC48C' }">
											<template #title>
												<h3 style="color: #1EC48C;">Delivered</h3>
											</template>
											<template #prefix>
												<h3 class="mr-3" style="color: #1EC48C">{{ totalDelivered }}</h3>
											</template>
										</a-statistic>
									</a-card>
								</a-col>
								<a-col :span="8">
									<a-card>
										<a-statistic :value="failedPercentage" suffix="%"
											:value-style="{ color: '#FD637C' }">
											<template #title>
												<h3 style="color: #FD637C;">Failed</h3>
											</template>
											<template #prefix>
												<h3 class="mr-3" style="color: #FD637C">{{ totalFailed }}</h3>
											</template>
										</a-statistic>
									</a-card>
								</a-col>
							</a-row>
							<div class="mt-3">
								<div v-if="!error">
									<a-card :bordered="false" :loading="loading">
										<div data-html2canvas-ignore="true" class="dF aC jE">
											<span class="mr-3">Chart Type:</span>
											<a-radio-group button-style="solid" v-model="chartType" @change="onTypeChange">
												<a-radio-button value="line">
													Line
												</a-radio-button>
												<a-radio-button value="bar">
													Bar
												</a-radio-button>
											</a-radio-group>
										</div>
										<eChartLine :option="chartOptions" :key="'title'" />
									</a-card>
								</div>
								<div v-else class="mt-10" style="text-align: center">
									<h4>Something went wrong. Please Try again!</h4>
									<a-button type="primary" @click="fetchChartData">Retry</a-button>
								</div>
							</div>
						</section>
					</vue-html2pdf>
				</a-tab-pane>
			</a-tabs>
			<a-modal v-model="lockModal" title="Lock Project" ok-text="LOCK PROJECT" @ok="lockProject"
				:confirmLoading="confirmLoading">
				<p>
					All users will be blocked from accessing this project
					<strong>{{ project.name }}</strong>.
				</p>
				<a-checkbox v-model="chargeForSuspension">
					<span>
						Charge for suspension
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								Upon selecting the checkbox, one invoice will be generated with $249(USD) amount.
							</template>
							<a-icon type="question-circle" style="font-size: 12px" />
						</a-tooltip>
					</span>
				</a-checkbox>
			</a-modal>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import BhLoading from "bh-mod/components/common/Loading";
import DatePicker from "@/components/common/datePicker.vue";
import PDFOrientation from "@/components/common/pdfOrientation.vue";
import eChartLine from "@/components/common/eChartLine";

export default {
	components: { BhLoading, DatePicker, PDFOrientation, VueHtml2pdf, eChartLine },

	data() {
		return {
			loading: false,
			error: false,
			confirmLoading: false,
			lockModal: false,
			chargeForSuspension: false,
			totalAccepted: 0,
			totalDelivered: 0,
			totalFailed: 0,
			currentWidth: window.innerWidth,
			domainStats: [],
			keysToSum: ["accepted", "delivered", "failed"],
			chartType: 'line',
			chartOptions: {
				title: {
					text: '',
					left: 'center'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {
					top: 'bottom'
				},
				grid: {
					left: '1%',
					right: '1%',
					top: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: []
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: 'Processed',
						type: 'line',
						data: [],
						smooth: true,
						color: "#2B93C6",
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					},
					{
						name: 'Delivered',
						type: 'line',
						data: [],
						smooth: true,
						color: "#1EC48C",
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					},
					{
						name: 'Failed',
						type: 'line',
						data: [],
						smooth: true,
						color: "#FD637C",
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
		}
	},

	computed: {
		...mapState(["selectedDomain", "currentStartDate", "currentEndDate", "pdfOrientation"]),

		project() {
			return this.selectedDomain && this.selectedDomain.instance || {}
		},

		deliveredPercentage() {
			if (this.totalDelivered && this.totalAccepted) {
				return ((this.totalDelivered / this.totalAccepted) * 100).toFixed(2)
			}
			return 0
		},

		failedPercentage() {
			if (this.totalFailed && this.totalAccepted) {
				return ((this.totalFailed / this.totalAccepted) * 100).toFixed(2)
			}
			return 0
		}
	},

	methods: {
		...mapMutations(["SET_DOMAIN"]),

		getTotal(item) {
			if (typeof item === 'object') {
				if (Array.isArray(item)) {
					let sum = 0;
					for (const element of item) {
						sum += this.getTotal(element);
					}
					return sum;
				} else {
					let sum = 0;
					for (const key in item) {
						sum += this.getTotal(item[key]);
					}
					return sum;
				}
			} else if (typeof item === 'number') {
				return item;
			} else {
				return 0;
			}
		},

		async fetchChartData({ startDate = this.currentStartDate, endDate = this.currentEndDate }) {
			this.error = false;
			this.loading = true;
			try {
				const { data } = await this.$api.get(`sending-domains/${this.$route.params.id}/stats?start=${startDate}&end=${endDate}`)
				const stats = data && data.stats || [];
				this.processData(stats)
			} catch (err) {
				this.loading = false;
				this.error = true;
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "An error occurred while fetching domain details. Please try again."))
				}
			}
			this.loading = false;
		},

		processData(stats) {
			let result = [];
			let totalAccepted = 0;
			let totalDelivered = 0;
			let totalFailed = 0;

			for (const obj of stats) {
				let date = obj["time"];
				let dataObj = {};

				for (const key of this.keysToSum) {
					if (obj[key] && typeof obj[key] === 'object') {
						if (key === "failed") {
							let keySum = 0;
							if (obj[key]["temporary"]) {
								keySum += obj[key]["temporary"]["total"] || 0;
							}
							if (obj[key]["permanent"]) {
								keySum += obj[key]["permanent"]["total"] || 0;
							}
							dataObj[key] = keySum;
							totalFailed += keySum;
						} else {
							dataObj[key] = obj[key]["total"] || 0;
							if (key === "accepted") {
								totalAccepted += dataObj[key];
							} else if (key === "delivered") {
								totalDelivered += dataObj[key];
							}
						}
					}
				}

				if (dataObj.accepted > 0 || dataObj.delivered > 0 || dataObj.failed > 0) {
					result.push({ [date]: dataObj });
				}
			}

			this.totalAccepted = totalAccepted
			this.totalDelivered = totalDelivered
			this.totalFailed = totalFailed

			let xAxisData = []

			result.forEach(r => {
				xAxisData.push(moment(Object.keys(r)[0]).format('ll'))
				let data = Object.values(Object.values(r)[0]);
				data.forEach((r, i) => {
					this.chartOptions.series[i].data.push(r)
				})
			})

			this.chartOptions.xAxis.data = xAxisData
		},

		lockProject() {
            this.confirmLoading = true;
            this.$api
                .post(`/instances/${this.project.id}/suspend`, { chargeForSuspension: this.chargeForSuspension })
                .then(() => {
                    this.$message.success('Selected project locked successfully');
					this.$router.push('/domains')
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while locking project. please try again!"))
					}
                })
                .finally(() => {
                    this.confirmLoading = false;
                    this.lockModal = false;
					this.chargeForSuspension = false;
                });
        },

		unlockProject() {
			let self = this
			this.$confirm({
				title: "Unlock Project",
				content: h => <div>Do you want to lock the project <strong>{self.project.name}</strong>?</div>,
				okText: 'UNLOCK',
				cancelText: 'CANCEL',
				centered: true,
				onOk() {
					self.loading = true;
					self.$api.post(`/instances/${this.project.id}/unlock`).then(() => {
						self.$message.success('Selected project unlocked successfully')
						self.$router.push('/domains')
					}).catch((err) => {
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, "Error occurred while locking project. please try again!"))
						}
					}).finally(() => {
						self.loading = false;
					})
				},
			})
		},

		downloadPDF() {
			this.$nprogress.start();
			this.$refs.html2Pdf.generatePdf();
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			options.filename = `${this.selectedDomain.name} (${this.project.name}).pdf`;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.save()
				.then(() => {
					this.$nprogress.done();
				})
				.catch(() => {
					this.$nprogress.done();
				});
		},

		onTypeChange(event) {
			this.chartOptions.series = this.chartOptions.series.map(s => {
				return {
					...s,
					type: event.target.value
				}
			})
		}
	},

	mounted() {
		if (!this.selectedDomain) {
			this.$message.error('Please select a domain first.')
			this.$router.push('/domains')
		}
	},

	beforeDestroy() {
		this.SET_DOMAIN(null);
	},
}
</script>

<style lang="scss" scoped>
.project-button {
	color: #fff;
	border-radius: 4px;
	font-size: 14px;
	text-align: center;
	padding: 2px 8px;
}
</style>
